import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useUsersList() {
  const role = JSON.parse(localStorage.getItem('userData')).role;
  const toast = useToast();
  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'user', label: 'Користувач', sortable: false, tdClass: 'custom-media-cell' },
    { key: 'phone', label: 'Номер телефону', sortable: false, tdClass: 'custom-media-cell' },
    { key: 'role', label: 'Роль', sortable: false, tdClass: 'custom-media-cell' },
    { key: 'status', label: 'Статус', sortable: false, tdClass: 'custom-media-cell' },
    { key: 'actions', label: 'Дії', tdClass: 'custom-media-cell' },
  ];

  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });

  watch([searchQuery, roleFilter, planFilter, statusFilter], () => {
    currentPage.value = 1;
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    let payload = {
      queryFullName: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      filterRole: roleFilter.value,
      status: statusFilter.value,
    };

    if (role !== 'support') {
      payload.onlyInternal = true;
    }

    store
      .dispatch('app-user/fetchUsers', payload)
      .then(response => {
        const users = response.data.items;
        const total = response.data.cursor.size;
        callback(users);
        totalUsers.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'owner') return 'warning';
    if (role === 'admin') return 'warning';
    if (role === 'manager') return 'info';
    if (role === 'supervisor') return 'info';
    if (role === 'support') return 'danger';
    return 'primary';
  };

  const resolveUserRoleIcon = role => {
    if (role === 'owner') return 'AwardIcon';
    if (role === 'admin') return 'SettingsIcon';
    if (role === 'manager') return 'Edit2Icon';
    if (role === 'supervisor') return 'AlignCenterIcon';
    if (role === 'support') return 'AirplayIcon';
    return 'UserIcon';
  };

  const resolveUserStatusVariant = status => {
    if (status === 'BLOCKED') return 'danger';
    if (status === 'ENABLED') return 'success';
    if (status === 'WAITING_FOR_ACTIVATION') return 'primary';
    return 'secondary';
  };

  const roleOptionsAll = [
    { label: 'Власник', value: 'owner' },
    { label: 'Адміністратор', value: 'admin' },
    { label: 'Менеджер', value: 'manager' },
    { label: 'Супервайзер', value: 'supervisor' },
  ];

  if (role === 'support') {
    roleOptionsAll.push({label: 'Підтримка', value: 'support' });
  }

  let roleOptions = [
    { label: 'Власник', value: 'owner' },
    { label: 'Адміністратор', value: 'admin' },
    { label: 'Менеджер', value: 'manager' },
    { label: 'Супервайзер', value: 'supervisor' },
  ];

  if (role === 'supervisor') {
    roleOptions = [
      { label: 'Менеджер', value: 'manager' },
    ];
  } else if (role === 'admin') {
    roleOptions = [
      { label: 'Адміністратор', value: 'admin' },
      { label: 'Менеджер', value: 'manager' },
      { label: 'Супервайзер', value: 'supervisor' },
    ];
  } else if (role === 'support') {
    roleOptions = [
      { label: 'Підтримка', value: 'support' },
      { label: 'Адміністратор', value: 'admin' },
      { label: 'Менеджер', value: 'manager' },
      { label: 'Супервайзер', value: 'supervisor' },
    ];
  }

  const statusOptions = [
    { label: 'Заблокований', value: 'BLOCKED' },
    { label: 'Активний', value: 'ENABLED' },
    { label: 'Очікується реєстрація', value: 'WAITING_FOR_USER_REGISTRATION' },
  ];

  const getRole = role => {
    let roleOption = roleOptionsAll.find(function(option) {
      return option.value === role;
    });

    return roleOption.label;
  };

  const getStatus = status => {

    let statusOption = statusOptions.find(function(option) {
      return option.value === status;
    });

    if (statusOption) {
      return statusOption.label;
    }

    return status;
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    getRole,
    getStatus,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    roleOptions,
    statusOptions,
  };
}
