<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2 align-items-center">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-0">{{ fullName }}</h4>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Повне імʼя"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.fullName"
              :readonly="!isCanUpdate"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Номер телефону"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="userData.phone"
              readonly
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Роль"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              :disabled="!isCanUpdate"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>

        <!-- <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Статус"
            label-for="status"
          >
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="status"
              disabled
            />
          </b-form-group>
        </b-col> -->

      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save"
      v-if="isCanUpdate"
    >
      Зберегти зміни
    </b-button>
    <b-button
      variant="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="blockUser"
      v-if="isCanBlock"
    >
      Заблокувати
    </b-button>
    <b-button
      variant="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="unblockUser"
      v-else-if="isCanUnBlock"
    >
      Розблокувати
    </b-button>
    <small class="text-success" v-if="saved">Користувача збережено</small>
    <small class="text-danger" v-if="error">Помилка</small>
    <!-- <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button> -->
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormRadioGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from "@axios";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormRadioGroup,
    vSelect,
    flatPickr,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCanUpdate() {
      return this.userData.role !== 'support';
    },
    isCanBlock() {
      if (this.userData.status === 'BLOCKED') {
        return false;
      }

      if (this.userData.role !== 'support') {
        return true;
      }

      const userIsSupportRole = JSON.parse(localStorage.getItem('userData')).role === 'support';
      return this.userData.role === 'support' && userIsSupportRole;
    },
    isCanUnBlock() {
      if (this.userData.status !== 'BLOCKED') {
        return false;
      }

      if (this.userData.role !== 'support') {
        return true;
      }

      const userIsSupportRole = JSON.parse(localStorage.getItem('userData')).role === 'support';
      return this.userData.role === 'support' && userIsSupportRole;
    },
    fullName() {
      return (this.userData.role === 'support' ? 'Підтримка: ' : '' ) + this.userData.fullName;
    },
  },
  methods: {
    async save() {
      const response = await axiosIns
        .put(`/business/managers/${this.userData.profileId}`, {
          fullName: this.userData.fullName,
          role: this.userData.role,
        })
        .then(() => {
          this.saved = true
          this.error = false
        })
        .catch(() => {
          this.saved = false
          this.error = true
        })
    },
    async blockUser() {

      const response = await axiosIns
        .post(`/business/managers/${this.userData.profileId}/block`)
        .then(() => {
          location.reload()
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    },
    async unblockUser() {

      const response = await axiosIns
        .post(`/business/managers/${this.userData.profileId}/unblock`)
        .then(() => {
          location.reload()
        })
        .catch(() => {
          this.toast({
              component: ToastificationContent,
              props: {
                  title: 'Error fetching users list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
              },
          })
          return false
        })
    },
  },
  setup(props) {

    const toast = useToast();
    const { resolveUserRoleVariant, getStatus, statusOptions } = useUsersList();
    const role = JSON.parse(localStorage.getItem('userData')).role;

    let roleOptions = [
        { label: 'Адміністратор', value: 'admin' },
        { label: 'Менеджер', value: 'manager' },
        { label: 'Супервайзер', value: 'supervisor' },
        { label: 'Підтримка', value: 'support' },
    ];

    if (role === 'supervisor') {
        roleOptions = [
            { label: 'Менеджер', value: 'manager' },
        ]
    } else if (role === 'admin') {
        roleOptions = [
            { label: 'Менеджер', value: 'manager' },
            { label: 'Супервайзер', value: 'supervisor' },
        ]
    }

    // ? Demo Purpose → Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    const saved = false
    const error = false

    return {
      resolveUserRoleVariant,
      getStatus,
      avatarText,
      roleOptions,
      statusOptions,
      refInputEl,
      previewEl,
      inputImageRenderer,
      saved,
      error,
      toast,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
