import axiosIns from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/business/managers', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/business/managers?filterProfileId=' + id)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/business/managers', userData)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
